@import '../../design-system/src/scss/_mixins.scss';
@import '../../design-system/src/scss/_breakpoints.scss';

.ds-login-box {
  box-shadow: var(--card-shadow);
  background: rgb(101, 137, 185);
  background: linear-gradient(180deg, rgba(101, 137, 185, 1) 0%, rgba(61, 78, 109, 1) 80%);
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
  height: 400px;
  width: 437px;
  max-width: 100%;

  h2,
  h2 a {
    font-size: var(--ds-typography-sizes-h-4-size);
    line-height: var(--ds-typography-sizes-h-4-line-height);
  }

  @media screen and (max-width: 1024px) {
    width: 395px;
  }

  @include etchedBackgroundFull();

  &__buttons {
    display: flex;
    margin-bottom: var(--spacer-6);
    button {
      position: relative;
      transition: background var(--animation-speed-fast) linear;
      height: 65px;
      width: 50%;
      background-color: var(--secondary-color-darkest);
      color: var(--text-color-white);
      font-size: 0.875rem;
      font-weight: 600;
      &.active {
        background-color: transparent;
        z-index: 0;
      }
      &:focus {
        outline: none;
      }
      &:focus:not(:focus-visible) {
        outline: none;
      }
      &:focus-visible {
        outline: auto;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 var(--spacer-6) var(--spacer-6);

    h2 {
      color: var(--text-color-white);
    }

    @include breakpoint(sm) {
      padding: 0 var(--spacer-3) var(--spacer-6);

      h2 {
        font-size: 1.25rem;
      }
    }
  }
}
