@import '../../scss/_mixins.scss';
@import '../../scss/_breakpoints.scss';

$stripe-top-height: 70px;
$stripe-middle-height: 45px;
$stripe-bottom-height: 60px;

.home-container {
    flex-direction: column;
    align-items: flex-start;

    @include min-breakpoint(md) {
      align-items: center;
      flex-direction: row;
    }
}

.ds-hero {
  --hero-height: 75ch;
  --ratio: calc(100vh / 100vw);
  position: relative;
  overflow: hidden;
  object-fit: cover;

  &--with-background {
    min-height: 50vh;
    display: flex;
    align-items: center;
  }

  &__background,
  &__background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__background {
    z-index: 2;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgb(255, 255, 255, 0.85);
      z-index:1;
    }
  }
  &__stripe {
    position: relative;
    z-index: 1;
    top: calc($stripe-top-height * -1);
    .stripe-top {
      opacity:.7;
      border-radius: 40px 0 0 0;
      width:60%;
      height:$stripe-top-height;
      position: absolute;
      right:0;
      left:auto;
      z-index:2;
    }
    .stripe-middle {
      filter: brightness(110%);
      border-radius: 0 40px 0 0;
      width:70%;
      height:$stripe-middle-height;
      position: relative;
      left:0;
      right:auto;
      top:25px;
      z-index:5;
    }
    .stripe-bottom {
      width:100%;
      height:$stripe-bottom-height;
      position: relative;
      top:25px;
      z-index:10;
    }
    /* these are the current options in the CMS color content type */
    &.plum {
      .stripe-top,
      .stripe-middle,
      .stripe-bottom {
        background-color: var(--ds-color-primary);
      }
    }
    &.primaryLight {
      .stripe-top,
      .stripe-middle,
      .stripe-bottom {
        background-color: var(--ds-color-primary-light);
      }
    }
    &.green {
      .stripe-top,
      .stripe-middle,
      .stripe-bottom {
        background-color: var(--ds-color-tertiary);
      }
    }
    &.orange {
      .stripe-top,
      .stripe-middle,
      .stripe-bottom {
        background-color: var(--ds-color-accent);
      }
    }
    &.blue {
      .stripe-top,
      .stripe-middle,
      .stripe-bottom {
        background-color: var(--ds-color-secondary);
      }
    }
  }

  &__normal {
    h1, .ds-hero__intro {
      max-width: 829px;
      margin: 0 auto;
      text-wrap: balance;
    }
  }

  &__wide {
    h1, .ds-hero__intro {
      max-width: 1300px;
      margin: 0 auto;
      text-wrap: balance;
    }
  }

  &__narrow {
    h1, .ds-hero__intro {
      max-width: 600px;
      margin: 0 auto;
      text-wrap: balance;
    }
  }

  &__center {
    h1, .ds-hero__intro {
      margin: 0 auto;
    }
  }

  &__right {
    h1, .ds-hero__intro {
      margin-left: auto;
    }
  }

  &__left {
    h1, .ds-hero__intro {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__breadcrumbs {
    color: var(--primary-color-light);
    font-weight: 400;
    font-size: 0.875rem;

    a {
      font-weight: 500;
    }
  }

  &__static {
    color: var(--ds-color-text-heading);
    font-weight: 500;
  }

  .intro-paragraph {
    color: var(--ds-color-text-heading);
  }
  .has-background-image {
    padding:3rem 0 6rem;
    text-align:left;

    & .white, &__breadcrumbs, & .white h1, & .white p {
      color: var(--body-background-color);
    }
  }

  &__desktopBackground {
    display:block;
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom: 0;
    .ds-hero__desktopBackground-image {
      display:block!important;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  &__mobileBackground {
    display:none;
  }
  @include breakpoint(md) {
    &__desktopBackground {
      display:none;
    }
    &__mobileBackground {
      display:block;
      position: absolute;
      left:0;
      right:0;
      top:0;
      bottom:0;
      div, picture {
        height:100%;
        width: 100%;
      }
    }
  }
  [class*="ds-container"] {
    z-index:3;
  }
}
