@import '../../scss/breakpoints';
@import '../../scss/_mixins.scss';

.ds-footer {
  --footer-background-color: var(--primary-color);

  min-height: 680px;

  &--simple {
    min-height: 149px;
    background-color: rgba(88, 88, 88, 1);
    color: #fff;
    display: flex;
    align-items: center;

    a {
      color: #fff;
    }

    p {
      margin-bottom: 10px;
    }
  }

  .horizontal-divider {
    height: 1px;
    background-color: rgba(151, 151, 151, 0.2);
    width: calc(100% - 385px);

    @include breakpoint(md) {
      display: none;
    }
  }

  strong {
    display: inline-block;
    width: auto;
    font-size: 1rem;
    color: rgba(59, 62, 65, 0.6);
  }

  .footer-text {
    max-width: 320px;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--text-color-light);

    @include breakpoint(md) {
      text-align: center;
      margin-left: 0;
      max-width: 100%;
      margin-bottom: var(--spacer-4);
    }
  }

  &__signup {
    // border: solid 1px rgba(151, 151, 151, 0.2);
    // border-radius: 4px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    input[type='text'] {
      border: none;
      padding: 0 var(--spacer-2);
      height: 50px;
      font-size: 0.875rem;
      flex-basis: 100%;
    }
    button {
      right: 12px;
      --ds-button-height: 47px;
    }

    @include breakpoint(md) {
      white-space: normal;
      flex-direction: column;
      height: auto;
      padding: 8px;
      justify-content: center;

      input[type='text'] {
        flex: 0 0 50px;
        width: 100%;
        margin: 0 auto var(--spacer-2);
        display: block;
      }

      button {
        display: block;
        width: 320px;
        flex: 0 0 100%;
        max-width: inherit;
      }
    }
  }

  .footer-content {
    display: grid;
    grid-template-columns: 250px auto;
    gap: 120px;

    @media screen and (max-width: 1024px) {
      gap: 60px;
    }
  }
  .columns {
    ul {
      justify-content: space-evenly;
      @include breakpoint(md) {
        column-gap:36px;
      }
      li.footer-links {
        @include footerLink();
        a {
          color: var(--text-color-light);

          &:hover {
            color: var(--primary-color-light);
          }
        }

        > a {
          font-size: 1rem;
          &.column-heading {
            color: var(--ds-color-text-heading);
            font-weight: 500;
            display: block;
            margin-bottom: -1rem;

            &:hover {
              color: var(--primary-color-light);
            }
          }
        }
        .highlight-link {
          color: var(--primary-color-light);
          font-weight: 500;
          font-size: 1rem;
        }
        ul li {
          @include footerLink();
        }

        &__normal {
          a {
            color: #4f5963; //var(--text-color-light);
            font-weight: 500;

            &:hover {
              color: var(--primary-color-light);
            }
          }
        }

        &__highlighted {
          a {
            color: var(--primary-color-light);
            font-weight: 800;

            &:hover {
              color: var(--primary-color-light);
            }
          }
        }
      }
    }
    @include breakpoint(sm) {
      width: 100%;
      text-align: center;
      margin-bottom: var(--spacer-8);
    }
  }

  .contact {
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: var(--spacer-8);
    }
  }

  &__fineprint {
    max-width: 920px;
    margin: 0 auto;
  }

  &__fineprint > * {
    color: rgba(60, 62, 65, 0.6);
    font-size: 0.875rem;
  }

  &__fineprint {
    ul {
      li {
        margin-bottom: 0;
      }
    }
    p {
      color: var(--text-color-light);
    }
    .footer-bottom {
      gap: 12px;
      --grid-gap: 12px;
    }
  }

  .copyright {
    color: var(--text-color-light);
  }

  .links {
    li {
      @include footerLink();
      padding: 0 var(--spacer-2);
      border-right: 1px solid var(--gray-color-light);
      // line-height: 1;
      &:last-child {
        border-right: none;
      }
      a {
        color: #4f5963; //var(--text-color-light);
      }
    }
    @include breakpoint(sm) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: var(--spacer-2) 0;
      li {
        display: block;
      }

      .sm-display-none {
        display: none;
      }
    }
  }
  .links-list {
    .links {
      li {
        margin-bottom: 0;
      }
    }
  }
  @include breakpoint(sm) {
    .footer-content {
      gap: var(--spacer-6);
      grid-template-columns: 1fr;
      .contact {
        margin-bottom: 0;
      }
      .columns {
        margin-bottom: 0;
        ul {
          display: block;
          li.footer-links {
            margin-bottom: var(--spacer-3);
            ul li {
              margin-bottom: 0;
              padding-top: var(--spacer-1);
            }
          }
        }
      }
    }
    &__signup button {
      right: 0;
    }
    &__fineprint {
      .footer-bottom {
        display: flex;
        flex-direction: column;
        gap: 0;
        --grid-gap: 0;
        .copyright {
          display: flex;
          order: 2;
          color: var(--text-color-light);
        }
        .links-list {
          display: flex;
          order: 3;
          li {
            display: block;
            width: 100%;
            margin-bottom: var(--spacer-2);
            border: none;
          }
        }
        .fdic {
          display: flex;
          text-align: center;
          order: 1;
        }
      }
    }
  }
}
