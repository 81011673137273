@import '../../scss/breakpoints';
@import '../../scss/_mixins.scss';

.social-links {
  display: flex;
  flex-direction: column;
  a {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    padding: 0 var(--spacer-3) var(--spacer-2);
    text-decoration: none;
    svg {
      filter: brightness(0.2);
    }
  }
  &.row {
    flex-direction: row;
    a {
      padding: 10px;
    }
  }
  &.light {
    a {
      svg {
        filter: brightness(0.5);
      }
    }
  }
  @include breakpoint(sm) {
    flex-direction: row;
    justify-content: center;
    margin: var(--spacer-4) 0;
    a {
      flex-grow: 1;
      padding: 0 0 var(--spacer-2);
    }
  }
}
