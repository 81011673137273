@import '../../scss/_breakpoints.scss';

.ds-select {
  position: relative;
  width: 100%;
  max-width: 600px;
  select {
    width: 100%;
    padding-right: 50px;
    color: #58585a;
    max-width: 600px;
    height: 55px;
    background-color: #fff;
    &.small {
      width:112px;
      padding-right:50px;
  }
}

  &::after {
    content: '';
    background-image: url(/select-triangle.svg);
    position: absolute;
    right: 30px;
    top: calc(50% - 2px);
    width: 13px;
    height: 5px;
    pointer-events: none;
  }
  @include breakpoint(md) {
    max-width:none;
    select {
      max-width:none;
    }
  }
}
